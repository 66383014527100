import React, { useMemo } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logoSvg from "../assets/logo/peekupLogo.svg";
import { ChevronLeft } from "@mui/icons-material";
import esmagicoLogo from "../assets/logo/esmagico.svg";
import { mapAllowedSectionsToMenu } from "../const/sidebar";

const Sidebar = ({ drawerWidth, isNonMobile }) => {
  // Map icons to the data received from API

  const { pathname } = useLocation();
  const [active, setActive] = useState(
    pathname === "/" ? "/overview" : pathname
  );
  const [pathnameArray] = useState([]);
  const navigate = useNavigate();
  const allowed_sections = useSelector((state) => {
    return state?.rolePermission?.allowed_sections ?? [];
  });

  const navItems = useMemo(
    () => mapAllowedSectionsToMenu(allowed_sections),
    [allowed_sections]
  );

  // Initialize expandedItems with all accordions open
  const [expandedItems, setExpandedItems] = useState(() => {
    const initialState = {};
    navItems.forEach((item) => {
      if (item.subMenu && item.subMenu.length > 0) {
        initialState[item.title] = true;
      }
    });
    return initialState;
  });

  const handleAccordionClick = (item) => {
    if (!expandedItems[item.title]) {
      // navigate(item.path);
    }
    setExpandedItems((prev) => ({
      ...prev,
      [item.title]: !prev[item.title],
    }));
  };

  const renderMenuItem = (item, depth = 0) => {
    const hasSubMenu = item.subMenu && item.subMenu.length > 0;
    const lcText = item.title.toLowerCase();
    const isActive = active === item?.path;
    const isOfferPath = item.path.startsWith("/test");
    const isDisabled = isOfferPath && !pathnameArray.includes(item.path);

    if (hasSubMenu) {
      return (
        <Accordion
          key={item.title}
          expanded={expandedItems[item.title]}
          onChange={() => handleAccordionClick(item)}
          sx={{
            width: "100%", // Full width
            backgroundColor: "white",
            color: "#0B1B32",
            // border: "1px solid red",
            fontFamily: "Proxima Nova",
            padding: "0",
            boxShadow: "none",
            // border: "none",
            "&:before": {
              display: "none",
            },
            "&.Mui-expanded": {
              margin: "0",
              backgroundColor: depth > 0 ? "white" : "white",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ChevronLeft
                sx={{
                  rotate: "270deg",
                  transition: "color 0.3s",
                }}
              />
            }
            sx={{
              minHeight: "32px",
              height: "32px",
              justifyContent: "space-between",
              alignItems: "center",
              "&.Mui-expanded": {
                minHeight: "32px",
                height: "32px",
                marginTop: "16px",
                backgroundColor: depth > 0 ? "white" : "white",
              },
              "& .MuiAccordionSummary-content": {
                justifyContent: "flex-start",
                alignItems: "center",
                paddingY: 0,
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                color: "#B5BBC5",
              },
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                color: "#0B1B32",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                height: 25,
                width: 25,
                marginRight: "10px",
                visibility: item?.icon ? "visible" : "hidden",
              }}
              alt="Menu icon"
              src={item?.icon}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Proxima Nova",
                color: "#1D1F2C",
              }}
            >
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: depth > 0 ? "#F5F6F7" : "white",
              color: "#0B1B32",
              padding: 0,
              fontFamily: "Proxima Nova",
              width: "100%", // Full width for details
            }}
          >
            {item.subMenu.map((subItem) => renderMenuItem(subItem, depth + 1))}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return (
        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            width: "100%",

            maxWidth: "100%",
          }}
          key={item.title}
          disablePadding
          disableGutters
        >
          <ListItemButton
            onClick={() => {
              if (!isDisabled && !isActive) {
                navigate(item.path);
                setActive(item?.path);
              }
            }}
            sx={{
              width: "100%", // Ensures full width
              height: "35px",
              backgroundColor: isActive ? "#F5F6F7" : "transparent",
              borderRight: isActive ? "3px solid #003D86" : "none",
              color: isDisabled ? "gray" : "black",
              paddingLeft: depth > 0 ? `${(depth + 1) * 25}px` : "16px",
              "&:hover": {
                backgroundColor: isActive ? "#F5F6F7" : undefined,
                cursor: isDisabled ? "default" : "pointer",
              },
              pointerEvents: isDisabled ? "none" : "auto",
            }}
          >
            {depth === 0 ? (
              <Box
                component="img"
                sx={{
                  height: 25,
                  width: 25,
                  marginRight: "10px",
                }}
                alt="Menu icon"
                src={item?.icon}
              />
            ) : (
              // <FiberManualRecordIcon
              //   sx={{ fontSize: 8, mr: "10px", visibility: "hidden" }}
              // />
              <></>
            )}
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                fontFamily: "Proxima Nova",
                color: "#1D1F2C",
                fontWeight: item?.isHeader ? 600 : 400,
                fontSize: item?.isHeader ? "14px" : "13px",
                lineHeight: "12px",
              }}
            />
          </ListItemButton>
        </ListItem>
      );
    }
  };

  return (
    <Box>
      <Drawer
        open={true}
        variant="persistent"
        anchor="left"
        sx={{
          width: "220px",
          "& .MuiDrawer-paper": {
            backgroundColor: "white",
            color: "#0B1B32",
            border: "1px solid #F0F1F3",
            boxSizing: "border-box",
            boxShadow: "2.82px 0px 21.11px 0px #8362EA0D",
            width: "220px",
          },
        }}
      >
        <Box
          width="100%"
          sx={{
            height: "100vh",
            overflowY: "auto",
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <div
            className="logo-container"
            style={{
              width: "220px",
              height: "68px",
              boxSizing: "border-box",
              borderBottom: "1px solid #F0F1F3",
              padding: "15px 0 0 18px",
              position: "sticky",
              top: 0,
              zIndex: 999,
              background: "#fff",
            }}
          >
            <Box>
              <img
                style={{
                  width: "165px",
                }}
                src={logoSvg}
                alt=""
              />
            </Box>
          </div>

          <Box
            sx={{
              flex: "1 1 auto",
              overflowY: "auto",
              height: "calc(100vh)",
              "scrollbar-width": "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <List>{navItems.map((item) => renderMenuItem(item))}</List>
          </Box>

          {/* <Box
            position="sticky"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "220px",
              height: "40px",
              borderTop: "0.5px solid #F0F1F3",
              backgroundColor: "white",
              bottom: 0,
              left: 0,
            }}
          >
            <img
              style={{
                width: "96px",
              }}
              src={esmagicoLogo}
              alt=""
            />
          </Box> */}
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
