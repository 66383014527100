import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const PrimaryButton = styled(Button)({
  backgroundColor: "#043A87",
  color: "#fff",
  fontFamily: "Proxima Nova",
  border: "1px solid #043A87",
  boxSizing: "border-box",
  fontWeight: 400,
  height: "30px",
  borderRadius: "8px",
  padding: "1px 10px",
  fontSize: "12px",
  lineHeight: "14px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#043A87",
  },
});

export const SecondaryButton = styled(Button)({
  backgroundColor: "#fff",
  fontFamily: "Proxima Nova",
  border: "1px solid #e0e2e7",
  fontWeight: 400,
  boxSizing: "border-box",
  height: "30px",
  color: "#858D9D",
  borderRadius: "8px",
  padding: "1px 10px",
  fontSize: "12px",
  textTransform: "capitalize",
  lineHeight: "14px",
  "&:hover": {
    backgroundColor: "#fff",
  },
});
