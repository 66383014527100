import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { findBreadcrumbTrail } from "../const/breadcrumb";
import arrow from "../assets/arrow.svg";
import { Link } from "react-router-dom";

const Breadcrumb = ({ menu, currentPath }) => {
  // Find the breadcrumb trail dynamically
  const breadcrumbTrail = findBreadcrumbTrail(menu, currentPath);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={
        <span style={{ color: "red", display: "flex", alignItems: "center" }}>
          <img
            src={arrow}
            alt="separator"
            style={{ verticalAlign: "middle", height: "12px", margin: "0 2px" }}
          />
        </span>
      }
    >
      {breadcrumbTrail.map((item, index) => {
        const isheader = item?.isHeader ?? false;
        const isLast = index === breadcrumbTrail.length - 1;

        return isLast ? (
          <Typography
            style={{
              fontFamily: "Proxima Nova",
              fontSize: "14px",
              fontWeight: 600,

              color: "#043A87",
            }}
            key={item.path}
            color="text.primary"
          >
            {item.title}
          </Typography>
        ) : (
          <Link
            key={item.path}
            to={item.path}
            style={{
              fontFamily: "Proxima Nova",
              color: "#667085",
              fontSize: "14px",
              fontWeight: 600,

              textDecoration: "none",
            }}
            color="inherit"
          >
            {item.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
