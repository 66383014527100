import React from "react";
import SearchBar from "./SearchBar";
import FilterByButton, { SortByButton } from "../FilterByButton";
import { Box, Button } from "@mui/material";
import usePermission from "../../hooks/usePermission";
import { PrimaryButton, SecondaryButton } from "../button";
import DownloadIcon from "@mui/icons-material/Download";

const SearchActionComponent = ({
  value,
  setState,
  placeholder,
  children,
  toggleDrawer,
  toggleSortDrawer,
  downloadFile,
  require = { search: true, filter: true, download: false, sort: false },
}) => {
  const permissions = usePermission();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
        alignItems: "center",
      }}
    >
      {require.search && (
        <SearchBar
          value={value}
          setState={setState}
          placeholder={placeholder}
        />
      )}
      {require.filter && <FilterByButton toggleDrawer={toggleDrawer} />}

      {require.sort && <SortByButton toggleDrawer={toggleSortDrawer} />}

      {require.download && permissions?.DOWNLOAD && (
        <SecondaryButton
          startIcon={
            <DownloadIcon
              sx={{
                height: "15px",
              }}
            />
          }
          onClick={() => downloadFile()}
        >
          Download
        </SecondaryButton>
      )}
      {children}
    </Box>
  );
};

export default SearchActionComponent;
