import { Box, IconButton, Stack, Typography } from "@mui/material";
import successCircleSvg from "../../assets/toast/success.svg";
import timeoutCircleSvg from "../../assets/toast/timeout.svg";
import warningCircleSvg from "../../assets/toast/timeout.svg";
import CloseIcon from "@mui/icons-material/Close";

const getImagebyStatus = (status) => {
  switch (status) {
    case "success":
      return successCircleSvg;
    case "warning":
      return timeoutCircleSvg;
    case "error":
      return warningCircleSvg;
    default:
      return warningCircleSvg;
  }
};

const ToastNotification = ({
  imagePath,
  title,
  description,
  onClose,
  containerStyle = {},
  imageContainerStyle = {},
  titleStyle = {},
  descriptionStyle = {},
  closeToast,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#333",
        borderRadius: "8px",
        padding: "10px",
        minWidth: "300px",
        ...containerStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
          ...imageContainerStyle,
        }}
      >
        <img
          style={{ width: "24px", height: "24px" }}
          src={imagePath}
          alt="toast icon"
        />
      </Box>

      <Stack
        sx={{
          flex: 1,
          paddingLeft: "10px",
          minHeight: "66px",
        }}
        gap={"5px"}
        justifyContent={"center"}
      >
        {title && (
          <Typography
            sx={{ fontWeight: "bold", ...titleStyle }}
            variant="h6"
            fontSize={"14px"}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            sx={{ color: "#ccc", ...descriptionStyle }}
            variant="body2"
          >
            {description}
          </Typography>
        )}
      </Stack>

      <IconButton
        sx={{
          alignSelf: "flex-start", // Align to the top
          padding: "0",
          margin: "0",
          marginTop: "4px", // Optional: Adjust spacing from the top
        }}
        onClick={() => {
          closeToast();
        }}
      >
        <CloseIcon
          sx={{
            color: "#fff",
            width: "20px",
          }}
        />
      </IconButton>
    </Box>
  );
};

const getToastByStatus = (status, closeToast, title, description) => {
  const imagePath = getImagebyStatus(status);

  // css
  const containerStyle = {
    background: "inherit",
    borderRadius: "9px",
    boxSizing: "border-box",
    width: "100%",
    overflow: "hidden",
    color: "#fff",
    display: "flex",
  };

  const imageContainerStyle = {
    width: "44px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "66px", // Changed height to minHeight
  };

  const titleStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.49px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#F1F1F1",
  };

  const descriptionStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.8px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
  };

  switch (status) {
    case "success":
      return (
        // <Box sx={containerStyle}>
        //   <Box sx={imageContainerStyle}>
        //     <img
        //       style={{
        //         width: "24px",
        //         height: "24px",
        //       }}
        //       src={imagePath}
        //       alt="toast icon"
        //     />
        //   </Box>

        //   <Stack
        //     sx={{
        //       flex: 1,
        //       width: "100%",
        //       height: "100%",
        //       paddingLeft: "10px",
        //       minHeight: "66px", // Changed height to minHeight
        //     }}
        //     gap={"10px"}
        //     justifyContent={"center"}
        //   >
        //     <Typography sx={titleStyle} variant="h2" fontSize={"12px"}>
        //       Ride Canceled (Passenger)
        //     </Typography>
        //     <Typography sx={descriptionStyle} variant="caption">
        //       Booking ID 1234567 has been canceled by the passenger.
        //     </Typography>
        //   </Stack>

        //   <div>
        //     <IconButton>
        //       <CloseIcon
        //         sx={{
        //           color: "#fff",
        //           width: "20px",
        //         }}
        //       />
        //     </IconButton>
        //   </div>
        // </Box>

        <ToastNotification
          description={description}
          imagePath={imagePath}
          title={title}
          containerStyle={containerStyle}
          descriptionStyle={descriptionStyle}
          imageContainerStyle={imageContainerStyle}
          onClose={() => {}}
          titleStyle={titleStyle}
          closeToast={closeToast}
        />
      );
    case "warning":
      return (
        <ToastNotification
          description={description}
          imagePath={imagePath}
          title={title}
          containerStyle={containerStyle}
          descriptionStyle={descriptionStyle}
          imageContainerStyle={imageContainerStyle}
          onClose={() => {}}
          titleStyle={titleStyle}
          closeToast={closeToast}
        />
      );

    case "error":
      return (
        <ToastNotification
          description={description}
          imagePath={imagePath}
          title={title}
          containerStyle={containerStyle}
          descriptionStyle={descriptionStyle}
          imageContainerStyle={imageContainerStyle}
          onClose={() => {}}
          titleStyle={titleStyle}
          closeToast={closeToast}
        />
      );
    default:
      return <div></div>;
  }
};

const CustomToast = ({ data = {}, closeToast }) => {
  const status = data?.status ?? "success";
  const title = data?.title ?? "";
  const description = data?.description ?? "";

  return getToastByStatus(status, closeToast, title, description);
};

export default CustomToast;
