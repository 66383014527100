import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import axiosInstance from "../utils/axiosConfig";
import { formatDateNew } from "../utils/formateDate";
import { notifyToast } from "../utils/notify";

const BlockDatesModel = ({
  showBlockDates,
  handleBlockDatesClick,
  blockDate,
  accountStatus,
  getBlockDates,
  setBlockDate,
  muiltipleBlockDates,
  setMultipleBlockDates,
  trainingCenter,
  setTrainingCenter,
  handleTraningCenter,
  tranningDetails,
  setTrainingDetails,
}) => {
  function isValidDateRange(startDate, endDate) {
    // Parse the input strings into Date objects
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    // Check if the parsed start date is less than the parsed end date
    const isValidRange = parsedStartDate < parsedEndDate;

    return isValidRange;
  }

  function isDateGreaterOrEqual(inputDate) {
    // Get the current date in the format "${year}-${month}-${day}"
    var currentDate = new Date().toISOString().slice(0, 10);

    // Check if the input date is greater than or equal to the current date
    return inputDate >= currentDate;
  }

  const patchBlockDates = async () => {
    const url = "/crm/block-date/";
    const updateData = {
      training_center_id: tranningDetails.training_center_detail_id,
    };

    if (!blockDate && !muiltipleBlockDates.from && !muiltipleBlockDates.to) {
      notifyToast.error("Error", "Please Select date");
      return;
    }

    if (!tranningDetails.training_center_detail_id) {
      notifyToast.error(
        "Error",
        "Please select venu address to block appointment dates"
      );
      return;
    }

    if (blockDate) {
      if (!isDateGreaterOrEqual(formatDateNew(blockDate))) {
        notifyToast.error(
          "Error",
          "Please select a date greater than or equal to the current date.",
          {
            autoClose: 5000,
          }
        );

        return;
      }
      updateData["date"] = formatDateNew(blockDate);
    }

    if (!blockDate && muiltipleBlockDates.to && muiltipleBlockDates.from) {
      if (
        !isDateGreaterOrEqual(formatDateNew(muiltipleBlockDates.to)) ||
        !isDateGreaterOrEqual(formatDateNew(muiltipleBlockDates.from))
      ) {
        notifyToast.error(
          "Error",
          "Please select a date greater than or equal to the current date.",
          {
            autoClose: 5000,
          }
        );

        return;
      }
      let startDate = formatDateNew(muiltipleBlockDates.from);
      let endDate = formatDateNew(muiltipleBlockDates.to);

      if (isValidDateRange(startDate, endDate)) {
        updateData["start_date"] = startDate;
        updateData["end_date"] = endDate;
      } else {
        notifyToast.error("Error", "From should be greater then to");
      }
    }

    try {
      await axiosInstance.patch(url, updateData).then(() => {
        if (!blockDate && muiltipleBlockDates.to && muiltipleBlockDates.from) {
          setMultipleBlockDates({
            from: "",
            to: "",
          });
        }

        if (blockDate) {
          setBlockDate("");
        }

        if (accountStatus === "block dates") {
          getBlockDates();
        }

        setTrainingDetails({
          training_center_detail_id: "",
          appintment_detail_id: "",
          reporting_time: "",
          training_center_address: "",
        });

        notifyToast.success("Success", "Dates successfully blocked!");

        handleBlockDatesClick();
      });
    } catch (error) {
      notifyToast.error("Error", error);
      console.log("Error while Posting Block Dates", error);
    }
  };

  return (
    <Modal
      open={showBlockDates}
      onClose={handleBlockDatesClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "535px",
          height: "569px",
          borderRadius: "20px",
          background: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "483px",
            margin: "auto",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              color: "#000",
              fontSize: "20px",
              fontWeight: "600",
              fontStyle: "normal",
              letterSpacing: "0.4px",
            }}
            variant="h2"
          >
            Block Dates
          </Typography>
          <IconButton
            onClick={() => {
              handleBlockDatesClick();
            }}
          >
            <CloseIcon
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
        </Box>

        <Divider
          tick
          sx={{
            background: "#DBDBDB",
            width: "484px",
            margin: "13px auto 0 auto",
            border: "1px solid #DBDBDB",
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              width: "483px",
              margin: "20px auto 0 auto",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
                variant="h2"
              >
                Block a date
              </Typography>
              <DatePicker
                value={dayjs(blockDate)}
                disabled={muiltipleBlockDates.to || muiltipleBlockDates.from}
                onChange={(value) => setBlockDate(value.toISOString())}
                sx={{
                  width: "100%",
                }}
              />
            </Stack>

            <Divider />

            <Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
                variant="h2"
              >
                Block multiple dates
              </Typography>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  margin: "10px 0",
                }}
              >
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.50)",
                      fontFamily: "Proxima Nova",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.24px",
                      textTransform: "capitalize",
                    }}
                  >
                    From
                  </Typography>
                  <DatePicker
                    value={dayjs(muiltipleBlockDates.from)}
                    disabled={blockDate}
                    onChange={(value) =>
                      setMultipleBlockDates((prev) => ({
                        ...prev,
                        from: value.toISOString(),
                      }))
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                </Stack>

                <Stack spacing={1}>
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.50)",
                      fontFamily: "Proxima Nova",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.24px",
                      textTransform: "capitalize",
                    }}
                  >
                    To
                  </Typography>
                  <DatePicker
                    disabled={blockDate}
                    value={dayjs(muiltipleBlockDates.to)}
                    onChange={(value) =>
                      setMultipleBlockDates((prev) => ({
                        ...prev,
                        to: value.toISOString(),
                      }))
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </LocalizationProvider>

        <Divider
          tick
          sx={{
            background: "#DBDBDB",
            width: "484px",
            margin: "13px auto 0 auto",
            border: "1px solid #DBDBDB",
          }}
        />

        <TextField
          select
          label="Venue Address"
          sx={{ margin: "30px auto", width: "100%" }}
          value={trainingCenter.id}
          name="training_center_address"
          onChange={handleTraningCenter}
        >
          {trainingCenter.length > 0 &&
            trainingCenter.map((res, index) => (
              <MenuItem key={res.id} value={res.id} sx={{ width: "100%" }}>
                {res.training_center_address}
              </MenuItem>
            ))}
        </TextField>

        <Stack
          direction="row"
          spacing="auto"
          sx={{
            boxSizing: "border-box",
            width: "483px",
            position: "absolute",
            bottom: "35px",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleBlockDatesClick();
            }}
            sx={{
              width: "230px",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              color: "#000",
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: "230px",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              background: "#000",
              color: "#fff",
              "&:hover": {
                background: "#000",
              },
            }}
            variant="contained"
            onClick={patchBlockDates}
          >
            Block
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BlockDatesModel;
