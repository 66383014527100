import React from "react";
import { Box, Button, Typography } from "@mui/material";

const CommonTableHead = ({
  active = "",
  ButtonConfig = [],
  totalcount = {},
}) => {
  return (
    <Box
      sx={{
        mt: "20px",
        display: "flex",
        gap: "0.25rem",
      }}
    >
      {ButtonConfig.map((button) => (
        <Button
          key={button.label}
          variant="outlined"
          sx={{
            display: "flex",
            width: "152px",
            padding: "6px 10px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "10px 10px 0 0",
            border:
              active === button.value
                ? "1px solid #bfbfe6"
                : "1px solid #e4e4f0",
            backgroundColor: active !== button.value ? "#F0F4F8" : "#ffffff",
            "&:hover": {
              backgroundColor: active !== button.value ? "#D4E1F0" : "#fff",
              border:
                active === button.value
                  ? "1px solid #bfbfe6"
                  : "1px solid #e4e4f0",
            },
          }}
          onClick={() => button.onClick(button.value)}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: "14px",
              fontFamily: "Proxima Nova",
              textAlign: "right",
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "0.28px",
              textTransform: "capitalize",
              color: "#335B8C",
            }}
          >
            {button.label}
          </Typography>

          {active === button.value && (
            <Box
              sx={{
                display: "inline-flex",
                padding: "2px 5px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                backgroundColor: "#335B8C",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  textAlign: "right",
                  fontFamily: "Proxima Nova",
                  fontSize: "14px",
                  lineHeight: "normal",
                  fontWeight: "600",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                }}
              >
                {typeof totalcount === "object"
                  ? totalcount[button.value]
                  : totalcount}
              </Typography>
            </Box>
          )}
        </Button>
      ))}
    </Box>
  );
};

export default CommonTableHead;
