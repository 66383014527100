import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Box } from "@mui/material";

import { useLocation } from "react-router-dom";

import mqtt from "mqtt";
import GoogleMap from "./driverOnMap/GoogleMap";
import axiosInstance from "../utils/axiosConfig";

const DriverOnMaps = () => {
  const location = useLocation();
  const { socketId, operator_id } = useSelector((store) => {
    return {
      socketId: store.auth.websocket_room_id,
      operator_id: store.auth.operator_id,
    };
  }, shallowEqual);
  const [dataMap, setDataMap] = useState({});
  const scriptRef = useRef(false);

  // // mqtt connection
  useEffect(() => {
    const mqttBroker = process.env.REACT_APP_MQTT_BROKER;
    const mqttPort = process.env.REACT_APP_MQTT_PORT;
    const topic = process.env.REACT_APP_MQTT_TOPIC;

    if (!mqttBroker || !mqttPort || !topic) return;

    const options = {
      username: process.env.REACT_APP_MQTT_USERNAME,
      password: process.env.REACT_APP_MQTT_PASSWORD,
    };

    // making connection with mqtt
    const url = `wss://${mqttBroker}:${mqttPort}`;
    const client = mqtt.connect(url, options);

    if (client) {
      // When the MQTT client connects, subscribe to the specified topic
      client.on("connect", () => {
        client.subscribe(topic);
      });

      client.on("error", (error) => {
        console.log("MQTT Error:", error);
        client.end();
      });
    }
    client.on("message", (topic, message) => {
      const data = JSON.parse(message.toString());
      const incomingData = data;
      if (operator_id && operator_id !== incomingData?.data?.operator_id) {
        return;
      }

      // Update the dataMap based on the incoming data's ID
      setDataMap((prevDataMap) => {
        const newDataMap = { ...prevDataMap };

        if (incomingData?.data?.id in newDataMap) {
          // If data with the same ID exists, update the status
          if (
            newDataMap[incomingData?.data?.id] &&
            incomingData?.type === "driver_offline"
          ) {
            delete newDataMap[incomingData?.data?.id];
          } else if (
            newDataMap[incomingData?.data?.id] &&
            newDataMap[incomingData?.data?.id].type !== "driver_offline"
          ) {
            newDataMap[incomingData?.data?.id] = {
              ...newDataMap[incomingData.data.id],
              ...incomingData,
            };
          }
        } else {
          // If data with the ID doesn't exist, add the entire object
          if (
            incomingData?.data?.id &&
            incomingData.type !== "driver_offline"
          ) {
            newDataMap[incomingData?.data?.id] = incomingData;
          }
        }

        return newDataMap;
      });
    });

    // Cleanup function to unsubscribe and close the MQTT connection when the component unmounts
    return () => {
      client.unsubscribe(topic);
      client.end();
    };
  }, []);

  // fleet tracking

  // useEffect(() => {
  //   const DEFAULT_POLLING_INTERVAL_MS = 5000;

  //   const authTokenFetcher = async () => {
  //     const response = await axiosInstance.get(
  //       `/utils/get-fleet-engine-token/`
  //     );

  //     return {
  //       token: response?.data?.data?.token || "",
  //       expiresInSeconds: response?.data?.data?.expiry_time_in_min
  //         ? response.data.data.expiry_time_in_min * 60
  //         : 0,
  //     };
  //   };

  //   if (!window.google?.maps?.journeySharing) {
  //     console.error("Google Maps Journey Sharing library is not loaded.");
  //     return;
  //   }

  //   const provider =
  //     new window.google.maps.journeySharing.FleetEngineFleetLocationProvider({
  //       projectId: process.env.REACT_APP_FLEET_ENGINE_PROJECT_ID,
  //       authTokenFetcher,
  //       pollingIntervalMillis: DEFAULT_POLLING_INTERVAL_MS,
  //     });

  //   console.log(provider);

  //   provider.addListener("update", (e) => {
  //     console.log(e, "hit");
  //   });

  //   provider.addListener("error", (e) => {
  //     console.error("Trip Error:", e);
  //   });

  //   setTimeout(() => {
  //     provider.refresh(); // Forces the provider to fetch data and call authTokenFetcher
  //     console.log("Provider refresh triggered.");
  //   }, 2000);

  //   // return () => {
  //   //   provider.removeAllListeners();
  //   // };
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "80%",
        flexDirection: "column",
        padding: "32px",
      }}
    >
      <Box style={{ width: "100%", height: "90%" }}>
        <GoogleMap
          initialApiCoordinates={dataMap}
          scriptRef={scriptRef}
          locationState={location.state}
        />
      </Box>
    </div>
  );
};

export default DriverOnMaps;
