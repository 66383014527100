import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import sendMessgeSvg from "../../assets/comments/sendMessage.svg";
import postComments from "../../service/comments";

//css
const container = {
  width: "446px",
  position: "fixed",
  right: "0",
  bottom: "0",
  display: "flex",
  borderTop: "1px solid #00000024",
  padding: "12px",
  boxSizing: "border-box",
  alignItems: "flex-end",
};

const WriteCommentsInput = {
  flex: 1,
  width: "90%",
  minHeight: "48px",
  border: "1px solid #E2E4E8",
  borderRadius: "10px",
  paddingInline: "10px",
  paddingBlock: "2px",
  resize: "none",
  outline: "none",
};

// main component
const CommentsSendMessage = ({ rideId, getTrips }) => {
  const textAreaRef = useRef(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "20px";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = Math.min(scrollHeight, 150) + "px";
    }
  }, [message]);

  const clearMessage = () => {
    setMessage("");
  };

  const handleSendMessage = () => {
    if (message?.length > 0) {
      const url = "/crm/flag/rides/comment/";
      const obj = {
        comment: message,
        ride_id: rideId,
      };
      postComments(url, obj, {}, clearMessage).then((res) => {
        getTrips();
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box sx={container}>
      <textarea
        ref={textAreaRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Write Comment...."
        style={WriteCommentsInput}
      />
      <Box>
        <IconButton
          onClick={() => {
            handleSendMessage();
          }}
        >
          <img
            style={{
              width: "48px",
            }}
            src={sendMessgeSvg}
            alt="send-message-icon"
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CommentsSendMessage;
