import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddColumnIcon from "../assets/AddColumnIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiButton from "../components/MuiButton";
import { SecondaryButton } from "./button";

const AddColumn = ({ handleColumnAdd, columnFields, fieldsName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [data, setData] = useState(fieldsName);

  const tableFields = fieldsName
    ?.map((item, index) => {
      return {
        label: item.name,
        name: item.key,
      };

      // If the condition is not met, return undefined
    })
    .filter(Boolean); // Filter out any undefined values

  console.log(tableFields, "textFIld", tableFields);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, label) => {
    const { name, checked } = event.target;
    handleColumnAdd(checked, name, label);
  };

  return (
    <div>
      <SecondaryButton
        text=" Add Column"
        variant="outlined"
        onClick={handleClick}
        startIcon={
          <img
            style={{
              width: "14px",
            }}
            src={AddColumnIcon}
            alt="AddColumnIcon"
          />
        }
      >
        Add Column
      </SecondaryButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormGroup
        // onClick={handleChange}
        >
          {tableFields?.map((options) => {
            return (
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={options.name}
                      sx={{
                        color: "black",
                        "&.Mui-checked": {
                          color: "black",
                        },
                      }}
                      onClick={(e) => handleChange(e, options.label)}
                      checked={
                        !!columnFields?.find(
                          (fields) => fields.field === options.name
                        )
                      }
                    />
                  }
                  label={options.label}
                />
              </MenuItem>
            );
          })}
        </FormGroup>
      </Menu>
    </div>
  );
};

export default AddColumn;
