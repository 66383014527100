import Overview from "../assets/sidebar/overview.svg";
import Customers from "../assets/sidebar/customer.svg";
import DriverIcon from "../assets/sidebar/driver.svg";
import SupportIcon from "../assets/sidebar/support.svg";
import Admin from "../assets/sidebar/admin.svg";
import FullFillmentIcon from "../assets/sidebar/fullfillment.svg";

const svgIcons = {
  Overview,
  Customer: Customers,
  Drivers: DriverIcon,
  Support: SupportIcon,
  Admin: Admin,
  Flagged: Overview,
};

export function mapAllowedSectionsToMenu(data = []) {
  const menu = [];
  let currentMainMenu = null;
  let parentMenu = null;

  data.forEach((section, index) => {
    const nextMenu = data[index + 1];

    if (!section.url || (section?.url && !nextMenu?.url && !parentMenu)) {
      // Create a new main menu item
      if (currentMainMenu) {
        parentMenu = index;
        menu.push(currentMainMenu);
      }

      const path = section?.url ?? nextMenu?.url;

      currentMainMenu = {
        path: `/${path}`,
        title: section.text,
        icon: svgIcons[section.text] || null, // Set icon here if required
        subMenu: [],
        disabled: false,
        isHeader: true,
      };
    } else {
      // Add to current main menu's subMenu
      if (currentMainMenu) {
        currentMainMenu.subMenu.push({
          path: `/${section.url}`,
          title: section.text,
          disabled: false,
          isHeader: false,
        });
      }
    }
  });

  // Push the last main menu to the array
  if (currentMainMenu) {
    menu.push(currentMainMenu);
  }

  return menu;
}
