import { Box, Stack, Typography } from "@mui/material";
import chatSvg from "../../assets/comments/chat.svg";

const NoComments = () => (
  <Box
    sx={{
      width: "100%",
      height: "calc(100% - 180px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Stack
      maxWidth={"325px"}
      alignItems={"center"}
      textAlign={"center"}
      gap={"12px"}
    >
      <img
        style={{
          width: "120px",
        }}
        src={chatSvg}
        alt="chat-svg"
      />
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "20px",
          letterSpacing: "2%",
        }}
        variant="h1"
      >
        No Comments
      </Typography>
      <Typography
        sx={{
          color: "#000000B2",
          fontSize: "16px",
          //   fontWeight: 700,
          lineHeight: "20px",
          letterSpacing: "2%",
        }}
        variant="caption"
      >
        No Comments added yet start commenting to see them here
      </Typography>
    </Stack>
  </Box>
);

export default NoComments;
