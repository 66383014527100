import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import SearchBar from "./SearchBar";
import DataTable from "./DataTable";
import { showErrorToast } from "../utils/toastUtils";
import axiosInstance from "../utils/axiosConfig";
import formatNumberShort from "../utils/formatNumberShort";
import convertTimestampTo12HourFormat, {
  convertTo24HourFormat,
} from "../utils/convertTimestampTo12HourFormat";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import InitiateRefundSidePanel from "./InitiateRefundSidePanel";
import PaymentsFilter from "./PaymentsFilter";
import DetailsSidePanel from "./DetailsSidePanel";
import CustomerDetails from "./CustomerDetails";
import useDebounce from "../hooks/useDebounce";
import { truncateWithEllipsis } from "../utils/removeSlugFromString";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";
import CustomFilter from "./reusableComponents/Filter/CustomFilter";
import dayjs from "dayjs";
import FilteredResults from "./FilteredResults";
import { useSearchParams } from "react-router-dom";
import { removeQuery, setQuery } from "../utils/queryHelpers";
import useQueryParams from "../hooks/useQueryParams ";
import CommonTableHead from "./CommonTableHead";

const GenericText = ({
  variant = "body1",
  text,
  css = "",
  isEllipsis = false,
  maxlength,
}) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  return isEllipsis ? (
    <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
      {truncateWithEllipsis(text, maxlength)}
    </Typography>
  ) : (
    <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
      {text}
    </Typography>
  );
};

const ToggleButton = ({
  toggledrawer = () => () => {},
  setid = () => {},
  isEllipsis = false,
  maxlength,
  id,
  addCss = {},
  text,
  drawer,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const textCss = {
    color: "#2d5a9b",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };

  return (
    <>
      <BootstrapTooltip title={text}>
        <Button
          variant="text"
          onClick={(e) => {
            setid(id);
            // this  toggledrawer funtion return a funtion then we are passing event on it
            setQuery("id", id, setSearchParams);
            setQuery(drawer, true, setSearchParams);
            toggledrawer("right", true)(e);
          }}
        >
          <GenericText
            text={text}
            maxlength={maxlength}
            isEllipsis={isEllipsis}
            css={{ ...textCss, ...addCss }}
          />
        </Button>
      </BootstrapTooltip>
    </>
  );
};

const Payments = () => {
  const [isLoading, setisLoading] = useState(false);

  const permissions = usePermission();

  const { searchParams, setSearchParams, getQuery } = useQueryParams();

  // state to store payments data
  const [payments, setPayments] = useState([]);
  // state to store totalCount of payments
  const [totalCount, setTotalCount] = useState(0);

  const id = searchParams.get("id");
  // state to store the details id
  const [detailsId, setDetailsId] = useState(id || "");
  // state to store the customer id
  const [riderId, setRiderId] = useState(id || "");
  // state to store transaction id
  const [transactionId, setTransactionId] = useState(id || "");

  const [activeButton, setActiveButton] = useState("Payments");

  // state for dataGrid pagination
  const page = parseInt(searchParams.get("page"), 0) || 0;
  const pageSize = parseInt(searchParams.get("limit"), 15) || 15;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: pageSize || 15,
    page: page || 0,
  });

  const { timeFormat, timezone, formatDate, dateformat } = useSettings();

  // State for managing the search query
  const [search, setSearch] = useState("");

  // filter state
  const [selectedTime, setSelectedTime] = React.useState({
    minTime: getQuery("payment_time_gte")
      ? dayjs(getQuery("payment_time_gte"))
      : null,
    maxTime: getQuery("payment_time_lte")
      ? dayjs(getQuery("payment_time_lte"))
      : null,
  });

  const [paymentMode, setPaymentMode] = useState(
    getQuery("payment_mode") ?? ""
  );

  const [date, setDate] = useState({
    startDate: getQuery("payment_date_gte")
      ? dayjs(getQuery("payment_date_gte"))
      : null,
    endDate: getQuery("payment_date_lte")
      ? dayjs(getQuery("payment_date_lte"))
      : null,
  });

  const [paymentDay, setPaymentDay] = useState(getQuery("payment_day") ?? "");

  const [refundStatus, setRefundStatus] = useState(
    getQuery("refund_status") ?? ""
  );

  const [totalFare, setTotalFare] = useState(getQuery("total_fare") ?? "");

  // Debounce the search input to avoid frequent API calls or updates.
  const serachDebounce = useDebounce(search, 500);

  let BookingdetailsDrawer = searchParams.get("BookingDrawer");
  let TransactiondetailsDrawer = searchParams.get("transactionDrawer");
  let customerdetailsDrawer = searchParams.get("customerdrawer");

  // State to track the starting position of a swipeable component mui
  const [showDetails, setShowDetails] = useState({
    right: BookingdetailsDrawer || false,
  });

  const [showCustomerDetails, setShowCustomerDetails] = useState({
    right: customerdetailsDrawer || false,
  });

  const [showFilter, setShowFilter] = useState({
    right: false,
  });

  const [showTransactionDetails, setshowTransactionDetails] = useState({
    right: TransactiondetailsDrawer || false,
  });

  // ------------ funtion --------

  function convertTimeToAmPm(timeString) {
    const [hours, minutes, seconds] = timeString.split(":");
    const parsedDate = new Date(0, 0, 0, hours, minutes, seconds);

    // Format the time as "hh:mm am/pm"
    const formattedTime = parsedDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return formattedTime;
  }

  // Funtion to Builds and returns a query
  const buildQueryString = (download = false) => {
    const queryParameters = [];

    if (serachDebounce && search) {
      queryParameters.push(`search=${serachDebounce}`);
    }

    if (paymentDay) {
      queryParameters.push(`payment_day=${paymentDay}`);
    }

    if (date.startDate !== null && date.endDate !== null) {
      let startDate = `${date.startDate.year()}-${date.startDate.month()}-${date.startDate.date()}`;
      let endDate = `${date.endDate.year()}-${date.endDate.month()}-${date.endDate.date()}`;
      queryParameters.push(`date_gte=${startDate}&date_lte=${endDate}`);
    }

    // if (selectedTime.$d != "Invalid Date") {
    //   queryParameters.push(`payment_time=${formatTime(selectedTime.$d)}`);
    // }

    if (paymentMode) {
      queryParameters.push(`payment_method=${paymentMode}`);
    }

    if (refundStatus) {
      queryParameters.push(
        `refund_status=${refundStatus === "issued" ? true : false}`
      );
    }

    if (totalFare) {
      queryParameters.push(`total_fare_sort=${totalFare}`);
    }

    if (selectedTime.minTime && selectedTime.maxTime) {
      queryParameters.push(
        `min_payment_time=${convertTo24HourFormat(
          selectedTime.minTime
        )}&max_rating_time=${convertTo24HourFormat(selectedTime.maxTime)}`
      );
    }

    const queryString =
      queryParameters.length > 0 ? `&${queryParameters.join("&")}` : "";

    return queryString;
  };

  // function to fetch payemnts
  const getPayments = async () => {
    setisLoading(true);
    const query = buildQueryString();

    const url = `/crm/payment/?page=${paginationModel.page + 1}&limit=${
      paginationModel.pageSize
    }&payment_status=COMPLETED${query}`;

    try {
      const response = await axiosInstance.get(url);

      if (response) {
        setPayments(response?.data?.data?.records ?? []);

        setTotalCount(response?.data?.data?.total_count ?? 0);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setisLoading(false);
    }
  };

  // mui toggleDrawer funtion for side panel
  const createToggleDrawer = (setState) => (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState((prev) => ({ ...prev, [anchor]: open }));
  };

  // Create a ToggleDrawer function instance
  const toggleDetailsDrawer = createToggleDrawer(setShowDetails);

  const toggleCustomerDetailsDrawer = createToggleDrawer(
    setShowCustomerDetails
  );

  // Function to handle click event by name
  const handleSelectNameClick = (name) => {
    setActiveButton(name);
  };

  const toggleTranscationDetailsDrawer = (anchor, open) => (event) => {
    if (transactionId) {
      setTransactionId("");
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setshowTransactionDetails((prev) => ({ ...prev, [anchor]: open }));
  };

  const toggleFilterDrawer = createToggleDrawer(setShowFilter);

  // mui dataGrid columns
  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        // funtion to format date

        return (
          <>
            <ToggleButton
              text={params?.value}
              setid={setTransactionId}
              toggledrawer={toggleTranscationDetailsDrawer}
              id={params?.row?.id ?? ""}
              drawer={"transactionDrawer"}
            />
          </>
        );
      },
    },
    {
      field: "ride_details.booking_id",
      headerName: "Booking ID",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.ride_details?.booking_id) {
          return "--";
        }

        let id = params?.row?.ride_details?.booking_id.split("P").pop();

        // funtion to format date
        return (
          <>
            <ToggleButton
              text={params?.row?.ride_details?.booking_id ?? ""}
              setid={setDetailsId}
              toggledrawer={toggleDetailsDrawer}
              id={parseInt(id)}
              drawer={"BookingDrawer"}
            />
          </>
        );
      },
    },
    {
      field: "rider_details",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value?.phone) {
          return "--";
        }
        let phoneNumber = params.value.phone;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <Button
            variant="text"
            onClick={(e) => {
              setRiderId(params?.row?.rider_details?.id ?? "");
              setQuery("id", params?.row?.rider_details?.id, setSearchParams);
              setQuery("customerdrawer", true, setSearchParams);
              toggleCustomerDetailsDrawer("right", true)(e);
            }}
          >
            <Typography
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {number}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "rider_details.full_name",
      headerName: "Customer name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.rider_details?.full_name) {
          return "--";
        }
        return (
          <>
            <ToggleButton
              text={params?.row?.rider_details?.full_name}
              toggledrawer={toggleCustomerDetailsDrawer}
              id={params?.row?.rider_details?.id ?? ""}
              drawer={"customerdrawer"}
              setid={setRiderId}
              isEllipsis={true}
              maxlength={25}
              addCss={{
                width: "78px",
                overflow: "hidden",
              }}
            />
          </>
        );
      },
    },
    {
      field: "rider_details.nick_name",
      headerName: "Customer nick name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.rider_details?.nick_name) {
          return "--";
        }
        return (
          <>
            <ToggleButton
              text={params?.row?.rider_details?.nick_name}
              toggledrawer={toggleCustomerDetailsDrawer}
              id={params?.row?.rider_details?.id ?? ""}
              setid={setRiderId}
              isEllipsis={true}
              maxlength={25}
              drawer={"customerdrawer"}
              addCss={{
                width: "78px",
                overflow: "hidden",
              }}
            />
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: `Date(${dateformat})`,
      flex: 1,
      formatter: {
        created_at: (params) => formatDate(params?.value, timezone, dateformat),
        time: (params) =>
          formatDate(params?.row.created_at, timezone, timeFormat),
        rating: (params) => `(${params?.value})`,
        // Add other formatters as needed
      },
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        const date = formatDate(params?.value, timezone, dateformat);
        return (
          <>
            <GenericText text={date} />
          </>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        const time = formatDate(params.row?.created_at, timezone, timeFormat);
        return (
          <>
            <GenericText text={time} />
          </>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Mode",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <>
            <GenericText text={params.value} />
          </>
        );
      },
    },
    {
      field: "currency",
      headerName: "Total fare",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <>
            <GenericText text={params.value + " " + params?.row?.amount} />
          </>
        );
      },
    },
    {
      field: "ride_details.ride_end_time",
      headerName: "Drop off",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.ride_details?.ride_end_time) {
          return "--";
        }

        return (
          <>
            <GenericText
              text={formatDate(
                params?.row?.ride_details?.ride_end_time,
                timezone,
                timeFormat
              )}
            />
          </>
        );
      },
    },
    {
      field: "is_ticket_raised",
      headerName: "Tickets",
      flex: 1,
      renderCell: (params) => {
        if (params?.value === (null || undefined)) {
          return "--";
        }

        return (
          <>
            <GenericText text={params.value ? "Yes" : "No"} />
          </>
        );
      },
    },
    {
      field: "refund_payment_id",
      headerName: "Refund",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <GenericText text={!params.value === null ? "Initiated" : "N/A"} />
          </>
        );
      },
    },
  ];

  // config

  // config
  const sub_section_to_label = {
    payments: "Payments",
  };

  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? [];

  const tableHeadButtonConfig = allowed_sub_sections.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      totalCount,
      value: "Payments",
      onClick: handleSelectNameClick,
    };
  });

  const customeTimeHandler = (name, value, setState) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const customeDateHandler = (name, value, setState) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const filterOptions = [
    {
      summryText: "Payment day",
      filterName: "payment_day",
      state: paymentDay,
      setState: setPaymentDay,
      options: [
        { value: "yesterday", label: "Yesterday" },
        { value: "last_week", label: "Last Week" },
        { value: "last_month", label: "Last month" },
        { value: "last_year", label: "Last year" },
      ],
      date: {
        customDateState: date,
        customDateHeader: "Custom",
        filterName: ["payment_date_gte", "payment_date_lte"],
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
        setState: setDate,
        customeDateHandler,
      },
    },
    {
      summryText: "Payment time",
      time: {
        timeLabel: ["Min Time", "Max Time"],
        filterName: ["payment_time_gte", "payment_time_lte"],
        customTimeName: ["minTime", "maxTime"],
        timeState: selectedTime,
        setState: setSelectedTime,
        customeTimeHandler,
      },
    },
    {
      summryText: "Payment mode",
      filterName: "payment_mode",
      state: paymentMode,
      setState: setPaymentMode,
      options: [
        { value: "CASH", label: "Cash" },
        { value: "WALLET", label: "Wallet" },
        { value: "Card", label: "Bank" },
      ],
    },
    {
      summryText: "Refund status",
      filterName: "refund_status",
      state: refundStatus,
      setState: setRefundStatus,
      options: [
        { value: "issued", label: "Issued" },
        { value: "not issued", label: "Not issued" },
      ],
    },
    {
      summryText: "Total fare",
      filterName: "total_fare",
      state: totalFare,
      setState: setTotalFare,
      options: [
        { value: "min", label: "Min" },
        { value: "Max", label: "Max" },
      ],
    },
  ];

  const handleClear = () => {
    if (paymentDay) {
      setPaymentDay("");
    }

    if (date.startDate && date.endDate) {
      setDate({
        startDate: null,
        endDate: null,
      });
    }

    if (paymentMode) {
      setPaymentMode("");
    }

    if (selectedTime?.maxTime || selectedTime?.minTime) {
      setSelectedTime({
        minTime: null,
        maxTime: null,
      });
    }

    if (refundStatus) {
      setRefundStatus("");
    }

    if (totalFare) {
      setTotalFare("");
    }
  };

  const buttonConfig = {
    active: activeButton,
    totalcount: totalCount,
  };

  useEffect(() => {
    // fetching payments data

    getPayments();
  }, [
    serachDebounce,
    paginationModel.page,
    paginationModel.pageSize,
    paymentDay,
    date.startDate,
    date.endDate,
    paymentMode,
    refundStatus,
    totalFare,
    selectedTime.minTime,
    selectedTime.maxTime,
  ]);

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      <SearchActionComponent
        value={search}
        setState={setSearch}
        placeholder={"Search Transaction Id , Booking Id, Customer name"}
        toggleDrawer={toggleFilterDrawer}
      />

      {filterOptions && <FilteredResults options={filterOptions} />}

      <CommonTableHead
        active={buttonConfig?.active}
        ButtonConfig={tableHeadButtonConfig}
        totalcount={buttonConfig?.totalcount}
      />

      {/* intial refund side panel drawer */}
      <SwipeableDrawerComponent
        isOpen={showTransactionDetails["right"]}
        anchor="right"
        onClose={(e) => {
          removeQuery("transactionDrawer", setSearchParams);
          removeQuery("id", setSearchParams);
          toggleTranscationDetailsDrawer("right", false)(e);
        }}
      >
        <InitiateRefundSidePanel
          permissions={permissions}
          toggleDrawer={toggleTranscationDetailsDrawer}
          trancationId={transactionId}
        />
      </SwipeableDrawerComponent>

      {/* payments filter side panel */}
      <SwipeableDrawerComponent
        isOpen={showFilter["right"]}
        anchor="right"
        onClose={toggleFilterDrawer("right", false)}
      >
        <CustomFilter
          toggleDrawer={toggleFilterDrawer}
          filterOptions={filterOptions}
          handleClear={handleClear}
        />
      </SwipeableDrawerComponent>

      {/* details side panel */}

      <SwipeableDrawerComponent
        isOpen={showDetails["right"]}
        anchor="right"
        onClose={(e) => {
          removeQuery("BookingDrawer", setSearchParams);
          removeQuery("id", setSearchParams);
          toggleDetailsDrawer("right", false)(e);
        }}
      >
        <DetailsSidePanel
          DId={detailsId}
          anchor="right"
          specialFilter={"rider_ticket"}
          toggleDrawer={toggleDetailsDrawer}
        />
      </SwipeableDrawerComponent>

      {/* customer details side panel */}

      <SwipeableDrawerComponent
        isOpen={showCustomerDetails["right"]}
        anchor="right"
        onClose={(e) => {
          removeQuery("customerdrawer", setSearchParams);
          removeQuery("id", setSearchParams);
          toggleCustomerDetailsDrawer("right", false)(e);
        }}
      >
        <CustomerDetails
          riderId={riderId}
          setRiderId={setRiderId}
          toggleDrawer={toggleCustomerDetailsDrawer}
          anchor="right"
        />
      </SwipeableDrawerComponent>

      {/* table */}
      <DataTable
        row={payments || []}
        columns={columns}
        loading={isLoading}
        totalCount={totalCount}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};

export default Payments;
