// scriptSlice.js
import { createSlice } from "@reduxjs/toolkit";

const scriptSlice = createSlice({
  name: "script",
  initialState: {
    scriptStatus: "", // Store scripts by URL with their states
  },
  reducers: {
    setScriptState: (state, action) => {
      const status = action.payload;
      state.scriptStatus = status;
    },
  },
});

export const { setScriptState } = scriptSlice.actions;
export default scriptSlice.reducer;
