// React imports
import React, { useState } from "react";

// Mui imports
import { Box, Stack, Typography } from "@mui/material";

import logoSvg from "../assets/PeekupBlueCar.webp";
import { formatDateWithTime } from "../utils/formateDate";
import BottomCardHeader from "./ridetracking/BottomCardHeader";

import RequestedPhaseBottomCard from "./ridetracking/RequestedPhaseBottomCard";
import OngoingPhaseBottomCard from "./ridetracking/OngoingPhaseBottomCard";
import LottieIframe from "./ridetracking/rideLoading";

import JourneySharingComponent from "./JourneySharingComponent";
import {
  calculateDropoffTime,
  calculateMinutesUntilDropoff,
  rideStatus,
} from "../const/map";

function checkTripStatus(apiResponse, tripStatus) {
  if (apiResponse?.status === "COMPLETED") {
    return "COMPLETED";
  }
  if (apiResponse?.status === "CANCELLED") {
    return "CANCELLED";
  }
  return tripStatus;
}

export let strongTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 800,
  lineHeight: "normal",
  letterSpacing: "0.027px",
  textTransform: "capitalize",
  color: "var(--font-dark, #0F1828)",
};

export let thinTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "19px",
  fontStyle: "normal",
  textTransform: "capitalize",
  fontWeight: 100,
  lineHeight: "20px",
  letterSpacing: "0.027px",
  color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
};
export let bottomCardStyles = {
  height: "11%",
  background: "#FFF",
  color: "var(--font-dark, #0F1828)",
  display: "flex",
  gap: "8px",
  flexDirection: "column",
  // alignItems: "flex-start",
  padding: "20px",
  marginBottom: "20px",
};
export let bottomCardInnerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px",
};

export let groupCircleImagesStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
  marginRight: "18px",
  height: "100%",
};

export let driverImageStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  backgroundColor: "gray",
  // marginRight: "",
  // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
};
export let starStyle = {
  width: "30px",
  height: "30px",
  // marginLeft: "20px",
  // marginTop: "10px",
  // marginRight: "10px",
};
export let cashSvgStyle = {
  width: "35px",
  height: "35px",
  // marginLeft: "50vw",
  // marginRight: "5px",
};

const TripStatusHandler = ({ apiResponse = {}, isLoading = false }) => {
  //Styles
  let googleMapStyle = {
    flex: 1, // Allow the map container to take up available space
    display: "flex",
    flexDirection: "column", // To stack the map and data card vertically on small screens
    justifyContent: "center", // Center the map horizontally
  };

  const [trip, setTrip] = useState({});

  const [tripStatus, setTripStatus] = useState();

  const [tripError, setTripError] = useState(null);

  const onTripChange = (tripData) => {
    if (tripData) {
      setTrip(tripData);

      setTripStatus(tripData.status ? rideStatus[tripData.status] : undefined);
    } else {
      clearTrip();
    }
    setTripError(null);
  };

  const clearTrip = () => {
    setTrip(null);
    setTripStatus(null);
  };

  const getStatusText = (status, dropoff) => {
    switch (status) {
      case "COMPLETED":
        return `Your ride with this booking ID (${
          apiResponse?.booking_id ?? ""
        }) has been successfully completed on (${dropoff})`;
      case "CANCELLED":
        return `Your ride with this booking ID (${
          apiResponse?.booking_id ?? ""
        }) was cancelled on (${dropoff})`;
      // Add more cases as needed for other statuses
      default:
        return "invalid status";
    }
  };

  //Change styles as per mobile screen
  if (true) {
    driverImageStyle = {
      ...driverImageStyle,
      height: "50px",
      width: "50px",
    };

    strongTextStyle = {
      ...strongTextStyle,
      fontSize: "20px",
    };

    thinTextStyle = {
      ...thinTextStyle,
      fontSize: "17px",
    };

    starStyle = {
      ...starStyle,
      width: "20px",
      height: "20px",
      // marginLeft: "10px",
    };

    cashSvgStyle = {
      ...cashSvgStyle,
      width: "25px",
      height: "25px",
      // marginLeft: "40vw",
    };
  }

  const rstatus = checkTripStatus(apiResponse, tripStatus);

  const isStatusValid = ["COMPLETED", "CANCELLED"].includes(rstatus);

  return (
    <>
      {isLoading ? (
        <LottieIframe />
      ) : isStatusValid ? (
        <Box
          sx={{
            inset: "0 0 0 0",
            maxWidth: "480px",
            boxSizing: "border-box",
            margin: "0 auto",
            backgroundColor: "#fff",
            height: "100dvh",
            // border: "1px solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box>
            <Stack alignItems={"center"} width={"auto"} gap={"20px"}>
              <img
                src={logoSvg}
                alt="logo img"
                style={{ width: "200px", marginBottom: "20px" }}
              />
            </Stack>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "proxima nova",
                fontSize: { xs: "15px", sm: "17px" },
                textAlign: "center",
                fontWeight: 500,
                textTransform: "capitalize",
                paddingInline: { xs: "8px", sm: "20px" },
              }}
            >
              {getStatusText(
                rstatus ?? "",
                calculateDropoffTime(
                  trip?.dropOffTime || apiResponse?.updated_at
                )
              ) || "Your ride has been completed"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <div
          style={{
            display: !isStatusValid ? "flex" : "none",
            backgroundColor: "#F7F9FC",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            maxWidth: "480px",
            margin: "auto",
            height: "100vh", // Make the container cover the entire viewport height
            width: "100vw", // Make the container cover the entire viewport width
            alignContent: "center", // Center content vertically
            justifyContent: "center", // Center content horizontally
            flexDirection: "column",
            overflowX: "hidden", // Stack content vertically
            fontFamily: "Proxima Nova",
            // overflowY: "scroll",
          }}
        >
          {/* Google Map Component (centered and sized like a phone screen) */}
          <div style={googleMapStyle}>
            {/* Google Map Component */}
            {Object.keys(apiResponse).length !== 0 ? (
              <JourneySharingComponent
                trip={trip}
                status={tripStatus}
                apiResponse={apiResponse}
                onTripChange={onTripChange}
              />
            ) : (
              <></>
            )}
          </div>

          {/* Data Card (centered header)  */}
          <BottomCardHeader
            status={tripStatus}
            message={apiResponse?.message}
            eta_in_min={calculateMinutesUntilDropoff(
              tripStatus === "REQUESTED" ? trip?.pickupTime : trip?.dropOffTime
            )}
          />

          {/* Bottom Data Card (centered) */}
          {tripStatus === "REQUESTED" && (
            <RequestedPhaseBottomCard
              status={tripStatus}
              apiResponse={apiResponse}
              tripStatus={tripStatus}
              dropOffTime={calculateDropoffTime(trip?.pickupTime)}
            />
          )}

          {tripStatus === "ONGOING" && (
            <OngoingPhaseBottomCard
              status={tripStatus}
              apiResponse={apiResponse}
              dropOffTime={calculateDropoffTime(trip?.dropOffTime)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TripStatusHandler;
