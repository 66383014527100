export const findBreadcrumbTrail = (menu, currentPath) => {
  for (const item of menu) {
    if (item.path === currentPath) {
      if (item?.subMenu) {
        const trail = findBreadcrumbTrail(item.subMenu, currentPath);
        return [item, ...trail];
      }
      return [item];
    }

    if (item.subMenu && item.subMenu.length > 0) {
      const trail = findBreadcrumbTrail(item.subMenu, currentPath);
      if (trail.length) {
        return [item, ...trail];
      }
    }
  }
  return [];
};
