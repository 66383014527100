import { useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { MenuItem, Select, Typography } from "@mui/material";
import { notifyToast } from "../../utils/notify";

const FlagReviewStatus = ({
  id,
  value = "",
  getAppointments = () => {},
  authObj,
}) => {
  const [documentStatus, setDocumentStatus] = useState(value);

  const setAppointmentDocumentStatus = async (updateStatus) => {
    if (updateStatus === "--") {
      return;
    }
    const updateData = {
      is_flagged: true,
      flag_reviewed: updateStatus === "reviewed" ? true : false,
    };
    await axiosInstance
      .patch(`/crm/ride/update/${id}/`, updateData)
      .then((res) => {
        notifyToast.success("Success", "Review status updated successfully!");
        getAppointments();
      })
      .catch((error) => {
        notifyToast.error("Error", error);
      });
  };

  const handleChange = (e) => {
    setDocumentStatus(e.target.value);
    setAppointmentDocumentStatus(e.target.value);
  };

  const menuIteamStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.24px",
    textTransform: "capitalize",
  };

  const MenuIteamTextStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "175%",
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={documentStatus}
      label="Age"
      onChange={handleChange}
      fullWidth
      sx={{
        height: "20px",
        // padding: "4px 6px",
        color: documentStatus === "pending" ? "#FFC940" : "#009D0F",
        border: "none",
        boxShadow: "none",
        borderRadius: "40px",
        backgroundColor:
          documentStatus === "pending" ? "#FFC94017" : "#009D0F1A",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <MenuItem sx={menuIteamStyle} value={"pending"}>
        <Typography
          sx={{
            ...MenuIteamTextStyle,
          }}
        >
          Pending
        </Typography>
      </MenuItem>
      <MenuItem sx={menuIteamStyle} value={"reviewed"}>
        <Typography
          sx={{
            ...MenuIteamTextStyle,
          }}
        >
          Reviewed
        </Typography>
      </MenuItem>
    </Select>
  );
};

export default FlagReviewStatus;
