import React, { useRef } from "react";
import style from "../../styles/searchBar.module.css";
import SearchIcon from "../../assets/search.svg";

const SearchBar = ({ value, setState, placeholder = "Search here..." }) => {
  const searchBarRef = useRef(null);

  const handleFocus = () => {
    if (searchBarRef.current) {
      searchBarRef.current.focus();
    }
  };

  return (
    <div className={style.searchbar} onClick={handleFocus}>
      <img
        className={style["searchbar-icon"]}
        src={SearchIcon}
        alt="searchIcon"
      />
      <input
        ref={searchBarRef}
        value={value}
        onChange={(e) => setState(e.target.value)}
        placeholder={placeholder}
        className={style["searchbar-input"]}
        type="text"
      />
    </div>
  );
};

export default SearchBar;
