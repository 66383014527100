import { Box, Button } from "@mui/material";
import React from "react";
import { useParams, useNavigate } from "react-router";
import axiosInstance from "../utils/axiosConfig";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import usePermission from "../hooks/usePermission";
import { toast } from "react-toastify";
import { notifyToast } from "../utils/notify";

function removeProperties(inputObject) {
  // Create a new object without the specified properties
  const resultObject = Object.fromEntries(
    Object.entries(inputObject).filter(
      ([key]) => key !== "is_verified" && key !== "status"
    )
  );

  return resultObject;
}

const CreateDriverSaveButton = ({
  body = () => {},
  isDisabled,
  title,
  basicDetails,
  DriverDetailsValidationByApi,
  generateBasicDetailsBody,
  handleLoading = () => {},
  loading = {},
}) => {
  const permission = usePermission("/driver/:id");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const emailIsValid = emailPattern.test(email);
    return emailIsValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    const numberIsValid = phoneNumberPattern.test(phoneNumber);
    return numberIsValid;
  };

  const validateData = async () => {
    const checkEmail = validateEmail(basicDetails.email);

    const checkNumber = validatePhoneNumber(basicDetails.phoneNumber);

    if (!checkEmail && basicDetails.email) {
      toast.error("Invalid email address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!checkNumber) {
      toast.error("Invalid number format!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const checkUniqness = await DriverDetailsValidationByApi({
      phone: `+63${basicDetails?.phoneNumber ?? ""}`,
      ...(basicDetails?.email && { email: basicDetails?.email ?? "" }),
    });

    if (!checkUniqness) {
      return;
    }

    return true;
  };

  const handleSave = async () => {
    handleLoading(title, true);
    try {
      const url = `/crm/driver/`;

      const checkValidation = await validateData();

      if (!checkValidation) return;

      const saveBody = {
        body: [filterEmptyPropertiesRecursively(generateBasicDetailsBody())],
      };

      const response = await axiosInstance.post(url, saveBody);

      if (response) {
        navigate(`/driver/${response?.data?.data?.driver_id}`);
      }
      notifyToast.success("Success", "Successfully Saved!");
    } catch (error) {
      notifyToast.error("Error", error);
    } finally {
      handleLoading(title, false);
    }
  };

  const containerStyle = {
    width: "100%",
    padding: "0.9rem 1.5rem",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-end",
  };

  const saveButtonStyle = {
    width: "160px",
    backgroundColor: "#000",
    color: "#fff",
    height: "45px",
    borderRadius: "12px",
    "&:hover": {
      backgroundColor: "#000",
    },
  };

  return permission?.UPDATE ? (
    <Box sx={containerStyle}>
      <Button
        disabled={loading[title] ? true : false || !isDisabled}
        variant="contained"
        onClick={handleSave}
        sx={saveButtonStyle}
      >
        Save
      </Button>
    </Box>
  ) : (
    <></>
  );
};

export default CreateDriverSaveButton;
