import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { Stack } from "@mui/system";
import RoleSelectorButton from "./RoleSelectorButton";
import SwitchablePanel from "./SwitchablePanel";
import { useLocation, useSearchParams } from "react-router-dom";
import usePermission from "../hooks/usePermission";
import { removeQuery } from "../utils/queryHelpers";

const Overview = () => {
  // intial role name

  //"Customer", "Driver",
  const initialRoleName = [
    "Summary",
    "Customer",
    "Driver",
    "Drivers on Map",
    "Geo Fence",
  ];

  const location = useLocation();

  const config = useSelector(
    (store) => store.config.configValues.google_api_key
  );

  const permissions = usePermission();

  const [create, setCreate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCreateCLick = useCallback(() => {
    setCreate((prev) => !prev);
  }, []);

  const tab = searchParams.get("tab");

  const [selectRole, setSelectRole] = useState(() => {
    if (!location.state) {
      if (permissions?.SUB_SECTIONS) {
        return tab || permissions?.SUB_SECTIONS[0];
      }
    }

    return location?.state ? "Drivers on Map" : "Summary";
  });

  const handleTab = (value) => {
    if (value != "Geo Fence") {
      searchParams.delete("page");
      searchParams.delete("limit");
    }
    searchParams.set("tab", value);
    // Set the new search parameters with the replace option

    setSearchParams(searchParams, { replace: true });
    setSelectRole(value);
  };

  return (
    <Box
      sx={{
        margin: {
          xs: "0.85rem 0.85rem",
          sm: "1.5rem 1.5rem",
          boxSizing: "border-box",
        },
        // border: "1px solid red",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <RoleSelectorButton
          roleName={permissions?.SUB_SECTIONS ?? initialRoleName}
          selectedName={selectRole}
          roleHandler={handleTab}
          css={{}}
        />
        {permissions?.CREATE &&
          (selectRole === "Geo Fence" ? (
            <Button variant="outlined" onClick={handleCreateCLick}>
              {create ? "Cancel" : "Create"}
            </Button>
          ) : (
            <div className="empty_space_container"></div>
          ))}
      </Stack>

      <Box
        sx={{
          width: "100%",
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <SwitchablePanel
          selectedOption={selectRole}
          create={create}
          setCreate={setCreate}
        />
      </Box>
    </Box>
  );
};

export default Overview;
