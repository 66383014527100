import { toast } from "react-toastify";

export function showErrorToast(error, obj) {
  const checkType = typeof error === "string";
  let message = !checkType ? error?.response?.data?.message ?? "" : error;

  if (message) {
    toast.error(message, obj ? obj : {});
  }
}

export function showSuccessToast(message, options = {}) {
  // No Message is Provided It Will Return
  if (!message) return;
  toast.success(message, options);
}

export const getErrorMessage = (error) => {
  return error?.response?.data?.message || "Something went wrong";
};
