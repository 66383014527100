import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import axiosInstance from "../utils/axiosConfig";
import TrainingCenterForm from "./TrainingCenterForm";
import MenuComponent from "./MenuComponent";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import { formatTimeFromISOString } from "./NewTraningCenter";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import usePermission from "../hooks/usePermission";
import { notifyToast } from "../utils/notify";

const initialTraningCenterState = {
  training_center_name: "",
  training_center_latitude: "",
  training_center_longitude: "",
  training_center_address: "",
};

const TrainingCenterDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const permission = usePermission("/training-center/:id");

  const [slotOptions, setSlotOptions] = useState([]);
  const [trainingCenter, setTrainingCenter] = useState({
    ...initialTraningCenterState,
    update: initialTraningCenterState,
  });

  const [showConfirmDailog, setShowConfirmDailog] = useState({
    block: false,
    delete: false,
  });

  const updateslotOptionsRef = useRef([]);

  const [isBlocked, setIsBlocked] = useState(false);

  const menuOptions = [
    permission?.DELETE && {
      text: "Delete Center",
      status: "delete",
    },
    permission?.BLOCK && {
      text: `${isBlocked ? "UnBlock" : "Block"} Center`,
      status: "block",
    },
  ];

  const handleSlotOptions = (e, index) => {
    const { name, value } = e.target;

    setSlotOptions((prev) => {
      const updatedSlotOptions = prev.map((slotoption, i) =>
        index === i ? { ...slotoption, [name]: value } : slotoption
      );

      // Update the ref without triggering a render
      updateslotOptionsRef.current = updatedSlotOptions;

      return updatedSlotOptions;
    });
  };

  const handleTrainingCenter = (e, propvalue) => {
    if (!e) return;
    // for timing if there is no e.target that means we will consider e as name
    // also propvalue as value
    const { name, value } = e?.target || { name: e, value: propvalue };

    setTrainingCenter((prev) => ({
      ...prev,
      [name]: value,
      update: { ...prev.update, [name]: value },
    }));
  };

  const handleFromStartTime = (name, value, setState, index) => {
    if (!value) return;
    console.log(index);
    const toTime = slotOptions[index]?.end_time;
    if (toTime) {
      const fromTime = new Date(value);
      const totime = new Date(toTime);

      if (fromTime >= totime) {
        notifyToast.error("Error", "To should be greater than From");
        return;
      }
    }

    setSlotOptions((prev) =>
      prev.map((slotoption, i) =>
        index === i ? { ...slotoption, [name]: value } : slotoption
      )
    );
  };

  const handleToEndTime = (name, value, setState, index) => {
    if (!value) return;
    const fromTime = slotOptions[index]?.start_time;

    if (fromTime) {
      const from = new Date(fromTime);
      const to = new Date(value);

      if (from >= to) {
        notifyToast.error("Error", "To should be greater than From");
        return;
      }
    }

    setSlotOptions((prev) =>
      prev.map((slotoption, i) =>
        index === i ? { ...slotoption, [name]: value } : slotoption
      )
    );
  };

  const handleConformationDailog = (name, value) => {
    setShowConfirmDailog((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  function convertTimeToISOString(timeString) {
    const currentDate = new Date();

    const [hours, minutes] = timeString.replace(/[^\d:]/g, "").split(":");
    const isPM = timeString.toUpperCase().includes("PM");

    let adjustedHours = parseInt(hours, 10);
    if (isPM && adjustedHours !== 12) {
      adjustedHours += 12;
    } else if (!isPM && adjustedHours === 12) {
      adjustedHours = 0;
    }

    const resultDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      adjustedHours,
      parseInt(minutes, 10)
    );
    const isoString = resultDate.toISOString();

    return isoString;
  }

  function validateSlots(slotData = []) {
    if (slotData.length <= 1) return true;

    for (let i = 0; i < slotData.length; i++) {
      const { start_time, end_time } = slotData[i];

      // Check if both start and end time values exist
      if (!start_time || !end_time) {
        console.error(`Error: Slot at index ${i} has missing time values.`);
        return false; // Indicate error and stop further checks
      }

      const startTime = new Date(start_time);
      const endTime = new Date(end_time);

      for (let j = 0; j < slotData.length; j++) {
        if (i !== j) {
          const { start_time: otherStartTime, end_time: otherEndTime } =
            slotData[j];

          // Check if other slot also has both time values
          if (!otherStartTime || !otherEndTime) {
            console.error(`Error: Slot at index ${j} has missing time values.`);
            return false; // Indicate error and stop further checks
          }

          const otherStart = new Date(otherStartTime);
          const otherEnd = new Date(otherEndTime);

          // Check for overlap
          if (startTime < otherEnd && endTime > otherStart) {
            return false; // Overlap found
          }
        }
      }
    }

    return true; // No overlaps found
  }

  const updateCenterById = async () => {
    const url = `/crm/training-center-v2/${id}/`;

    if (slotOptions.length < 1) {
      notifyToast.reduce("Error", "At least one slot is required");
      return;
    }

    const isEverythingFilled = () => {
      return slotOptions.every((slotInfo) => {
        const requiredProperties = [
          "render_name",
          "start_time",
          "end_time",
          "default_capacity",
        ];

        return requiredProperties.every((property) => {
          return !!slotInfo[property]; // Check if property exists and has a truthy value
        });
      });
    };

    let updateBody = {};

    let trainingBody = filterEmptyPropertiesRecursively(trainingCenter.update);

    if (slotOptions.length < 1) {
      notifyToast.error("Error", "At least one slot is required");
      return;
    }

    if (!isEverythingFilled()) {
      notifyToast.error("Error", "please Fill All Slots Options");
      return;
    }

    if (slotOptions.length > 0) {
      const checkSlotsOverLap = validateSlots(slotOptions);

      if (!checkSlotsOverLap) {
        notifyToast.error(
          "Error",
          "Slots are overlapping. Please set them properly."
        );
        return;
      }

      const slot_name_and_default_capacity = slotOptions.reduce(
        (acc, currentOption, index) => {
          acc[currentOption?.render_name?.toUpperCase() ?? ""] = {
            ...currentOption,
            order:
              (acc[currentOption.render_name] &&
                acc[currentOption.render_name]["order"]) ??
              index + 1,
            render_time: `${formatTimeFromISOString(
              currentOption?.start_time,
              true
            )}`,
            start_time: formatTimeFromISOString(currentOption?.start_time),
            end_time: formatTimeFromISOString(currentOption?.end_time),
          };

          return acc;
        },
        {}
      );

      updateBody["slot_name_and_default_capacity"] =
        slot_name_and_default_capacity;
    }

    updateBody = { ...trainingBody, ...updateBody };

    try {
      const response = await axiosInstance.patch(url, updateBody);

      notifyToast.success("Success", "Successfully Updated the Center Details");
      navigate("/training-center");
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  const deleteCenterById = async () => {
    const url = `/crm/training-center-v2/${id}/`;
    try {
      await axiosInstance.delete(url).then((_) => {
        notifyToast.success("Success", "Successfully Deleted the Center");

        navigate("/training-center");
      });
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  const blockCenterById = async () => {
    try {
      const url = `/crm/training-center-v2/${id}/`;

      const updatedBody = {
        is_blocked: !isBlocked,
      };

      await axiosInstance.patch(url, updatedBody).then((_) => {
        notifyToast.success(
          "Sucess",
          `Successfuly ${isBlocked ? "Unblocked" : "Block"} This Center?`
        );
        navigate("/training-center");
      });
    } catch (error) {}
  };

  const performActionByName = (name, value) => {
    switch (name) {
      case "delete":
        handleConformationDailog(name, value);
        break;
      case "block":
        handleConformationDailog(name, value);
        break;
      default:
        break;
    }
  };

  //css

  const containerStyle = {
    m: "1.5rem",
    width: "58%",
  };

  const flexStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  useEffect(() => {
    const getTrainingCenterDetailsById = async () => {
      const url = `/crm/training-center-v2/${id}/`;
      try {
        const response = await axiosInstance.get(url);

        if (response) {
          const trainingDetailsData = response.data.data;
          setTrainingCenter((prev) => ({
            ...prev,
            training_center_address:
              trainingDetailsData?.training_center_address ?? "",
            training_center_name:
              trainingDetailsData?.training_center_name ?? "",
            training_center_latitude: trainingDetailsData?.latitude ?? "",
            training_center_longitude: trainingDetailsData?.longitude ?? "",
          }));

          if (trainingDetailsData?.metadata?.slot_name_and_default_capacity) {
            const slotoptions = Object.entries(
              trainingDetailsData?.metadata?.slot_name_and_default_capacity
            )
              .sort(([, a], [, b]) => a.order - b.order)
              .map(([key, value]) => {
                const transformedObj = Object.keys(value).reduce(
                  (acc, innerKey) => {
                    if (innerKey === "start_time") {
                      acc["start_time"] = convertTimeToISOString(
                        value[innerKey]
                      );
                    } else if (innerKey === "end_time") {
                      acc["end_time"] = convertTimeToISOString(value[innerKey]);
                    } else {
                      acc[innerKey] = value[innerKey];
                    }
                    return acc;
                  },
                  {}
                );

                return transformedObj;
              });
            setSlotOptions(slotoptions);
          }

          setIsBlocked(trainingDetailsData?.is_blocked);
        }
      } catch (error) {
        notifyToast.error("Error", error);
      }
    };

    getTrainingCenterDetailsById();
  }, []);

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          ...flexStyle,
          justifyContent: "space-between",
          width: "100%",
          padding: "12px 25px",
          backgroundColor: "#fff",
          boxSizing: "border-box",
          textTransform: "capitalize",
          fontWeight: 600,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            color: "#000",
            fontSize: "25px",
            fontWeight: "600",
            fontStyle: "normal",
            letterSpacing: "0.4px",
            gap: "18px",
          }}
          variant="h2"
        >
          Center Details
        </Typography>

        <MenuComponent
          options={menuOptions}
          handleEventByName={performActionByName}
        />
      </Box>

      <TrainingCenterForm
        handleTrainingCenter={handleTrainingCenter}
        trainingCenter={trainingCenter}
        slotOptions={slotOptions}
        setSlotOptions={setSlotOptions}
        handleSlotOptions={handleSlotOptions}
        handleFromStartTime={handleFromStartTime}
        handleToEndTime={handleToEndTime}
      />

      <Box
        sx={{
          padding: "12px 30px",
          display: "flex",

          alignItems: "center",
          boxSizing: "border-box",
          backgroundColor: "#fff",
        }}
      >
        {permission?.UPDATE && (
          <Button
            variant="outlined"
            onClick={updateCenterById}
            sx={{
              width: "80%",
              margin: "0 auto",
              height: "50px",
              borderRadius: "12px",
              background: "#000",
              border: "none",
              color: "#fff",
              "&:hover": {
                background: "#000",
              },
            }}
          >
            Update
          </Button>
        )}
      </Box>
      <CustomConfirmationDialog
        handleState={performActionByName}
        deleteById={deleteCenterById}
        isGroup={true}
        value={true}
        open={showConfirmDailog.delete}
        status="delete"
        message="Are you sure you want to delete this account?"
      />

      <CustomConfirmationDialog
        handleState={performActionByName}
        blockById={blockCenterById}
        isGroup={true}
        value={true}
        open={showConfirmDailog.block}
        status="block"
        message={`Are You Sure You Want To ${
          isBlocked ? "Unblocked" : "Block"
        } This Center?`}
      />
    </Box>
  );
};

export default TrainingCenterDetails;
