import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import PreviewMessage from "./pushnotification/PreviewMessage";
import MobileNotificationPreview from "./pushnotification/MobileNotificationPreview";
import PushNotificationDailog from "./pushnotification/PushNotificationDailog";
import axiosInstance from "../utils/axiosConfig";
import "../styles/pushNotification.css";
import Autocomplete from "./pushnotification/AutocompleteComponent";
import { notifyToast } from "../utils/notify";

// CSS styles
const inputStyle = {
  width: "12%",
  maxWidth: "472px",
  height: "35px",
  padding: "10px 10px 10px 16px",
  gap: "10px",
  borderRadius: "12px",
  border: "1px solid #E3E7EF",
  outline: "none",
};

const initialData = {
  title: "",
  description: "",
  target: "",
  saveInformationCheck: false,
};

const Label = ({ children, ...rest }) => {
  const labelStyle = {
    color: "#6F747E",
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.05px",
    textAlign: "left",
  };

  return (
    <label style={labelStyle} {...rest}>
      {children}
    </label>
  );
};

const LabelInputWrapper = ({ children }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  return <Box sx={containerStyle}>{children}</Box>;
};

const PushNotification = () => {
  const [data, setData] = useState(initialData);
  const [selectOptions, setSelectOptions] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [openModel, setModel] = useState(false);

  const buttonStyle = {
    backgroundColor: "#000000",
    color: loader ? "#000" : "#fff",
    maxWidth: "238px",
    width: "100%",
    padding: "12px 37px 12px 37px",
    borderRadius: "8px",
    height: "47px",
    "&:hover": {
      backgroundColor: "#000000",
    },
    "&:disabled": {
      cursor: "not-allowed",
    },
  };

  const handleChanges = (field, value) => {
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const sendNotification = async () => {
    if (Object.values(data).some((value) => value)) {
      try {
        setLoader(true);

        const body = {
          title: data.title,
          body: data.description,
          topic: data.target,
          is_saved: data.saveInformationCheck,
          // notification_count: count,
        };

        await axiosInstance.post(
          "/crm/notification/send-push-notification/",
          body
        );
        notifyToast.success("Success", "Notification Sent Successfully");
      } catch (error) {
        notifyToast.error("Error", error);
      } finally {
        setLoader(false);
      }
    } else {
      notifyToast.error("Error", "Please Fill At Least One Field");
      setModel(false);
    }
  };

  const fetchTopics = async () => {
    try {
      const res = await axiosInstance.get("/crm/notification/get-all-topics/");

      if (res?.data?.data?.topics) {
        const options = Object.entries(res.data.data.topics).map(
          ([label, value]) => ({
            label,
            value,
          })
        );
        setSelectOptions(options);
      }
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  const fetchDataList = async () => {
    try {
      const res = await axiosInstance.get(
        "/crm/notification/get-all-notification/"
      );

      setDataList(res?.data?.data?.records);
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!data?.description && !data?.title) return;

    if (!data?.target) {
      notifyToast.error("Error", "Please Select Target");
      return;
    }
    setModel(true);
  };

  useEffect(() => {
    fetchTopics();
    fetchDataList();
  }, []);

  return (
    <Box sx={{ m: "1.5rem 1.5rem", backgroundColor: "#fff", padding: "20px" }}>
      <Typography
        sx={{
          fontFamily: "Proxima Nova",
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "26px",
        }}
        variant="h1"
      >
        Compose Notification
      </Typography>

      <Box sx={{ display: "flex" }}>
        <Stack sx={{ width: "60%", gap: "20px", mt: "28px" }}>
          <LabelInputWrapper>
            <Label>Notification Title</Label>
            <Autocomplete
              suggestions={dataList
                ?.filter((item) => item.text_type === "TITLE")
                ?.map((item) => ({ text: item.text_value }))}
              onSelect={(value) => handleChanges("title", value)}
              placeholder="Enter Title"
            />
          </LabelInputWrapper>
          <LabelInputWrapper>
            <Label>Notification Description</Label>
            <Autocomplete
              suggestions={dataList
                ?.filter((item) => item.text_type === "BODY")
                ?.map((item) => ({ text: item.text_value }))}
              onSelect={(value) => handleChanges("description", value)}
              placeholder="Notification Description"
              multiline
            />
          </LabelInputWrapper>
          <LabelInputWrapper>
            <Label>Target</Label>
            <Select
              name="target"
              value={data.target}
              displayEmpty
              onChange={(e) => handleChanges("target", e.target.value)}
              sx={{
                maxWidth: "478px",
                height: "45px",
                borderRadius: "12px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E3E7EF",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                },
                "& .MuiSelect-select": {
                  color: "#8c8996",
                },
              }}
            >
              <MenuItem value="">--Select Target--</MenuItem>
              {selectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </LabelInputWrapper>
          {/* <LabelInputWrapper>
            <Label>Push Notification Count</Label>
            <input
              onChange={(e) => {
                const count = e.target.value;
                setCount(count);
              }}
              style={inputStyle}
              type="number"
              placeholder={"Count"}
            />
          </LabelInputWrapper> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={data.saveInformationCheck}
              onChange={(e) =>
                handleChanges("saveInformationCheck", e.target.checked)
              }
              sx={{
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
            Save Data
          </div>
          <Button
            onClick={handleSubmit}
            disabled={loader || (!data.description && !data?.title)}
            variant="contained"
            sx={buttonStyle}
          >
            {loader ? (
              <CircularProgress style={{ color: "#000" }} />
            ) : (
              "Send Text Message"
            )}
          </Button>
        </Stack>
        <Stack
          sx={{
            width: "40%",
            boxSizing: "border-box",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "fit-content",
              border: "1px solid #E3E7EF",
              display: "flex",
              flexDirection: "column",
              padding: "15px",
              gap: "15px",
              borderRadius: "5px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "26px",
              }}
              variant="body1"
            >
              Device Preview
            </Typography>
            <PreviewMessage />
            <MobileNotificationPreview
              title={data.title}
              description={data.description}
            />
            <MobileNotificationPreview
              headerText="Apple (IOS)"
              title={data.title}
              description={data.description}
            />
          </Box>
        </Stack>
        <PushNotificationDailog
          PushNotificationState={data}
          open={openModel}
          setOpen={setModel}
          handlefetch={sendNotification}
          options={selectOptions}
        />
      </Box>
    </Box>
  );
};

export default PushNotification;
