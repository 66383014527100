import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
          [theme.breakpoints.up("lg")]: {
            display: "none",
          },
        }),
      },
    },
  },
});

const RoleSelectorButton = ({
  roleName = [],
  roleHandler = () => {},
  selectedName = "",
}) => {
  const isSmallScreen = useMediaQuery("(max-width:450px)");

  return isSmallScreen ? (
    <ThemeProvider theme={theme}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="responsive-select-label" shrink>
          Select
        </InputLabel>
        <Select
          displayEmpty
          sx={{
            backgroundColor: "#fff",
          }}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedName}
          onChange={(e) => roleHandler(e.target.value)}
          label="Select"
        >
          {roleName?.map((res) => {
            return (
              <MenuItem
                sx={{
                  textTransform: "capitalize",
                }}
                value={res}
                key={res}
              >
                {res}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  ) : (
    <Box
      sx={{
        boxSizing: "border-box",
        display: { xs: "none", sm: "flex" },
        flexWrap: "wrap",
        width: "auto",
        padding: "8px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        border: "1px solid #E0E0E0", // Softer border color for theme matching
        background: "#FFFFFF", // Maintain the white background
        gap: "10px", // Slightly larger gap for spacing
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
      }}
      onClick={(event) => {
        const clickedButton = event.target.closest("button");
        if (clickedButton) {
          const clickedName = clickedButton.getAttribute("data-name");

          if (!clickedName) return;

          roleHandler(clickedName);
        }
      }}
    >
      {roleName.map((name) => (
        <Button
          key={name}
          variant={selectedName === name ? "contained" : "outlined"} // Outlined for unselected state
          sx={{
            display: "flex",
            width: "auto",
            height: "28px",
            padding: "8px 32px", // Adjust padding for uniformity
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px", // Slightly smaller border radius
            backgroundColor: selectedName === name ? "#115293" : "#FFFFFF", // Match theme colors
            border: "none",
            "&:hover": {
              border: "none",
              backgroundColor: selectedName === name ? "#115293" : "#F5F5F5", // Subtle hover effect
            },
          }}
          data-name={name}
        >
          <Typography
            sx={{
              color: selectedName === name ? "#FFFFFF" : "#000000", // White text for selected state
              fontFamily: "Proxima Nova, Arial, sans-serif", // Font consistency
              fontSize: "14px", // Slightly smaller font size for compactness
              fontWeight: 600,
            }}
          >
            {name}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default RoleSelectorButton;
