import { Menu, styled } from "@mui/material";

// custom menu
export const CustomMenu = styled((props) => (
  <Menu
    id="basic-button"
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    zIndex: theme.zIndex.modal, // Keep it above other components
    borderRadius: "12px",
    marginTop: "10px",
    minWidth: 180,
    color: theme.palette.text.primary, // Use theme's text color
    backgroundColor: theme.palette.background.paper, // Match the theme's background
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle and soft shadow
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.text.secondary, // Use a secondary text color
        marginRight: "8px",
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover, // Hover effect
      },
    },
  },
}));
